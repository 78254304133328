var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-wrapper" }, [
      _c("div", { staticClass: "left-container" }, [
        _c("div", { staticClass: "angel-text" }, [
          _vm._v("The New Form of Angel Investing"),
        ]),
        _c("div", { staticClass: "navigation-div" }, [
          _c("div", { staticClass: "anchor-div" }, [
            _vm._v("\n        Hire a Team\n        "),
            _c("img", {
              attrs: { src: "/img/onboarding/blue-arrow.svg", alt: "" },
            }),
          ]),
          _c("div", { staticClass: "anchor-div" }, [
            _vm._v("\n        Sign up Your Team\n        "),
            _c("img", {
              attrs: { src: "/img/onboarding/blue-arrow.svg", alt: "" },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "right-wrapper" }, [
        _c("img", {
          attrs: { src: "/img/onboarding/investing-icon.svg", alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }