var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-container" }, [
      _c("div", { staticClass: "social-media-container" }, [
        _c("div", { staticClass: "company-location" }, [
          _vm._v("816 37th st. Unit G Oakland CA 94608"),
        ]),
      ]),
      _c("div", { staticClass: "copy-right-container" }, [
        _c("div", { staticClass: "company-copyright-text" }, [
          _c("div", { staticClass: "branch-name" }, [_vm._v("GRUPA")]),
          _c("div", { staticClass: "copyright-text" }, [
            _vm._v(
              "\n        © Copyright 2019 | This is a property of Platohub Inc.\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "privacy-policy" }, [
          _vm._v("Privacy Policy"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }