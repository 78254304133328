<template>
  <div class="section-wrapper">
    <div class="left-container">
      <div class="angel-text">The New Form of Angel Investing</div>
      <div class="navigation-div">
        <div class="anchor-div">
          Hire a Team
          <img src="/img/onboarding/blue-arrow.svg" alt="" />
        </div>
        <div class="anchor-div">
          Sign up Your Team
          <img src="/img/onboarding/blue-arrow.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="right-wrapper">
      <img src="/img/onboarding/investing-icon.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-wrapper {
  background: #f7f7f8;
  padding: 96px 0px;
  display: flex;
  gap: 219px;
  justify-content: center;
  /* align-items: center;
  flex-direction: column; */
}
.left-container {
  width: 512px;
  text-align: left;
}
.angel-text {
  font-weight: 600;
  font-size: 46px;
  line-height: 120%;
  color: #1b1e22;
}
.navigation-div {
  margin-top: 24px;
}
.anchor-div {
  font-weight: normal;
  font-size: 18px;
  line-height: 135%;
  color: #0781f2;
  cursor: pointer;
}
</style>
