<template>
  <div>
    <TopComponent />
    <DescriptionComponent />
    <ActionComponent />
    <PageFooter />
  </div>
</template>

<script>
import TopComponent from "./partials/TopComponent.vue";
import DescriptionComponent from "./partials/DescriptionComponent.vue";
import ActionComponent from "./partials/ActionComponent.vue";
import PageFooter from "./partials/PageFooter.vue";
export default {
  props: {},
  components: {
    TopComponent,
    DescriptionComponent,
    ActionComponent,
    PageFooter,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
</style>
