var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-container" }, [
      _c("div", { staticClass: "logo-container" }, [
        _c("img", { attrs: { src: "/img/grupa-black-logo.svg", alt: "" } }),
      ]),
      _c("div", { staticClass: "landing-page-bold-text" }, [
        _vm._v(
          "\n    Accept your invitation and earn up to $1,250 when you refer others\n  "
        ),
      ]),
      _c("div", { staticClass: "landing-page-light-text" }, [
        _vm._v(
          "\n    Grupa connects top FAANG tech experts with fast rising innovative startups\n    that offer the opportunity to work on what you love and compensate in cash\n    and equity.\n  "
        ),
      ]),
      _c("div", { staticClass: "cards-container" }, [
        _c("div", { staticClass: "signup-white-card" }, [
          _c("div", { staticClass: "card-top-content" }, [
            _c("div", { staticClass: "card-top-text" }, [
              _vm._v("Hire a Team"),
            ]),
            _c("div", { staticClass: "card-arrow" }, [
              _c("img", {
                attrs: { src: "/img/onboarding/right-arrow.svg", alt: "" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "card-main-content" }, [
            _vm._v(
              "\n        Get a team of top FAANG Experts to build your product.\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "signup-white-card" }, [
          _c("div", { staticClass: "card-top-content" }, [
            _c("div", { staticClass: "card-top-text" }, [
              _vm._v("SIGN UP YOUR TEAM"),
            ]),
            _c("div", { staticClass: "card-arrow" }, [
              _c("img", {
                attrs: { src: "/img/onboarding/right-arrow.svg", alt: "" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "card-main-content" }, [
            _vm._v(
              "\n        Join Grupa to work with exciting startups for cash and equity.\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "signup-white-card card-black-bg" }, [
          _c("div", { staticClass: "card-top-content" }, [
            _c("div", { staticClass: "card-top-text white-text" }, [
              _vm._v("refer & earn"),
            ]),
            _c("div", { staticClass: "card-arrow" }, [
              _c("img", {
                attrs: { src: "/img/onboarding/right-arrow.svg", alt: "" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "card-main-content grey-text" }, [
            _vm._v(
              "\n        Refer top experts & founders and earn big when they start a project.\n      "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }