<template>
  <div class="section-container">
    <div class="founders-wrapper">
      <div class="founders-card">
        <div class="founder-text">FOUNDERS</div>
        <div class="founder-content">
          Work with the Best Product Engineering Teams in the world
        </div>
        <div class="founder-light-text">
          Grupa connects you with the best Silicon Valley product engineering
          teams at FAANG companies who are available to invest their time and
          expertise to build the next big thing.
        </div>
      </div>
      <div class="raised-card">
        <div class="swat-team-title">SWAT Team</div>
        <div class="swat-team-content">
          We are a team of ex-FAANG and current FAANG employees with an average
          of 11 years experience building and scaling products like Instagram
          and Gmail...
        </div>
        <div class="swat-tools">
          <ChipButton
            text="ML"
            backgroundColor="rgba(27, 30, 34, 0.1);"
            textColor="#1B1E22"
          />
          <ChipButton
            text="Finance"
            backgroundColor="rgba(84, 189, 212, 0.1)"
            textColor="#54BDD4"
          />
          <ChipButton
            text="Web 3.0"
            backgroundColor="rgba(212, 84, 199, 0.1);"
            textColor="#D454C7"
          />
        </div>
        <div class="team-members-list">
          <div>
            <img src="/img/dummy/developer.png" alt="Developer" />
            <img
              class="more-members"
              src="/img/dummy/more-members.png"
              alt="Developer"
            />
          </div>
          <div class="open-compensation">
            Open to Cash + Equity
            <img src="/img/onboarding/coin-icon.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="experts-wrapper">
      <div class="raised-card">
        <div class="startup-tools">
          <div>
            <ChipButton
              text="Upcoming"
              backgroundColor="rgba(84, 207, 148, 0.1);"
              textColor="#54CF94"
            />
          </div>
          <div>
            <ChipButton
              text="Design"
              backgroundColor="rgba(122, 111, 246, 0.1)"
              textColor="#7A6FF6"
            />
            <ChipButton
              text="Engineering"
              backgroundColor="rgba(84, 189, 212, 0.1)"
              textColor="#54BDD4"
            />
          </div>
        </div>
        <div class="startup-text-content">
          Auto-generated ML media for brands/celebrities/ famous personalities
        </div>
        <div class="startup-investors">
          <div class="startup-cash-raised">
            <img src="/img/onboarding/coin-icon.svg" alt="" />
            Raised $2.5m seed
          </div>
          <div class="investors-list">
            <img src="/img/dashboard/investors/Y Combinator.svg" alt="" />
            <img src="/img/dashboard/investors/500 Startups.svg" alt="" />
            <img src="/img/dashboard/investors/Pear VC.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="experts-card">
        <div class="expert-text">EXPERTS</div>
        <div class="expert-text-content">
          Invest your skills and expertise for cash and equity
        </div>
        <div class="expert-card-text">
          Grupa connects you with the most innovative Venture-backed startups
          with the opportunity to invest your skills, time and expertise
          building generational and transformative products.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipButton from "@/components/chipButton/chipButton";

export default {
  props: {},
  components: {
    ChipButton,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-container {
  color: #ffffff;
  margin: 168px 0px 137px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.experts-wrapper,
.founders-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 209px;
}
.founders-card {
  width: 443px;
  text-align: left;
}
.founder-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #0781f2;
}
.expert-text-content,
.founder-content {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 135%;
  color: #1b1e22;
  margin-top: 16px;
}
.expert-card-text,
.founder-light-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #53585f;
  margin-top: 8px;
}
.raised-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(21, 23, 26, 0.05);
  border-radius: 4px;
  text-align: left;
  width: 410px;
  padding: 24px;
}
.swat-team-title {
  font-size: 14px;
  line-height: 145%;
  color: #000000;
}
.swat-team-content {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin-top: 12px;
}
.swat-tools {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}
.team-members-list {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.more-members {
  margin-left: -6px;
}
.open-compensation {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 9px;
}
.experts-wrapper {
  margin-top: 206px;
}
.startup-tools {
  display: flex;
  justify-content: space-between;
}
.startup-tools div {
  display: flex;
  gap: 3px;
}
.startup-text-content {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
  margin-top: 24px;
}

.startup-investors {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.startup-cash-raised {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.investors-list {
  display: flex;
  gap: 16px;
}
.experts-card {
  width: 443px;
  text-align: left;
}
.expert-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1fa564;
}
</style>
