var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-container" }, [
    _c("div", { staticClass: "founders-wrapper" }, [
      _vm._m(0),
      _c("div", { staticClass: "raised-card" }, [
        _c("div", { staticClass: "swat-team-title" }, [_vm._v("SWAT Team")]),
        _c("div", { staticClass: "swat-team-content" }, [
          _vm._v(
            "\n        We are a team of ex-FAANG and current FAANG employees with an average\n        of 11 years experience building and scaling products like Instagram\n        and Gmail...\n      "
          ),
        ]),
        _c(
          "div",
          { staticClass: "swat-tools" },
          [
            _c("ChipButton", {
              attrs: {
                text: "ML",
                backgroundColor: "rgba(27, 30, 34, 0.1);",
                textColor: "#1B1E22",
              },
            }),
            _c("ChipButton", {
              attrs: {
                text: "Finance",
                backgroundColor: "rgba(84, 189, 212, 0.1)",
                textColor: "#54BDD4",
              },
            }),
            _c("ChipButton", {
              attrs: {
                text: "Web 3.0",
                backgroundColor: "rgba(212, 84, 199, 0.1);",
                textColor: "#D454C7",
              },
            }),
          ],
          1
        ),
        _vm._m(1),
      ]),
    ]),
    _c("div", { staticClass: "experts-wrapper" }, [
      _c("div", { staticClass: "raised-card" }, [
        _c("div", { staticClass: "startup-tools" }, [
          _c(
            "div",
            [
              _c("ChipButton", {
                attrs: {
                  text: "Upcoming",
                  backgroundColor: "rgba(84, 207, 148, 0.1);",
                  textColor: "#54CF94",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("ChipButton", {
                attrs: {
                  text: "Design",
                  backgroundColor: "rgba(122, 111, 246, 0.1)",
                  textColor: "#7A6FF6",
                },
              }),
              _c("ChipButton", {
                attrs: {
                  text: "Engineering",
                  backgroundColor: "rgba(84, 189, 212, 0.1)",
                  textColor: "#54BDD4",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "startup-text-content" }, [
          _vm._v(
            "\n        Auto-generated ML media for brands/celebrities/ famous personalities\n      "
          ),
        ]),
        _vm._m(2),
      ]),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "founders-card" }, [
      _c("div", { staticClass: "founder-text" }, [_vm._v("FOUNDERS")]),
      _c("div", { staticClass: "founder-content" }, [
        _vm._v(
          "\n        Work with the Best Product Engineering Teams in the world\n      "
        ),
      ]),
      _c("div", { staticClass: "founder-light-text" }, [
        _vm._v(
          "\n        Grupa connects you with the best Silicon Valley product engineering\n        teams at FAANG companies who are available to invest their time and\n        expertise to build the next big thing.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "team-members-list" }, [
      _c("div", [
        _c("img", {
          attrs: { src: "/img/dummy/developer.png", alt: "Developer" },
        }),
        _c("img", {
          staticClass: "more-members",
          attrs: { src: "/img/dummy/more-members.png", alt: "Developer" },
        }),
      ]),
      _c("div", { staticClass: "open-compensation" }, [
        _vm._v("\n          Open to Cash + Equity\n          "),
        _c("img", { attrs: { src: "/img/onboarding/coin-icon.svg", alt: "" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "startup-investors" }, [
      _c("div", { staticClass: "startup-cash-raised" }, [
        _c("img", { attrs: { src: "/img/onboarding/coin-icon.svg", alt: "" } }),
        _vm._v("\n          Raised $2.5m seed\n        "),
      ]),
      _c("div", { staticClass: "investors-list" }, [
        _c("img", {
          attrs: { src: "/img/dashboard/investors/Y Combinator.svg", alt: "" },
        }),
        _c("img", {
          attrs: { src: "/img/dashboard/investors/500 Startups.svg", alt: "" },
        }),
        _c("img", {
          attrs: { src: "/img/dashboard/investors/Pear VC.svg", alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "experts-card" }, [
      _c("div", { staticClass: "expert-text" }, [_vm._v("EXPERTS")]),
      _c("div", { staticClass: "expert-text-content" }, [
        _vm._v(
          "\n        Invest your skills and expertise for cash and equity\n      "
        ),
      ]),
      _c("div", { staticClass: "expert-card-text" }, [
        _vm._v(
          "\n        Grupa connects you with the most innovative Venture-backed startups\n        with the opportunity to invest your skills, time and expertise\n        building generational and transformative products.\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }