import { render, staticRenderFns } from "./PageFooter.vue?vue&type=template&id=76c058c4&scoped=true&"
import script from "./PageFooter.vue?vue&type=script&lang=js&"
export * from "./PageFooter.vue?vue&type=script&lang=js&"
import style0 from "./PageFooter.vue?vue&type=style&index=0&id=76c058c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c058c4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76c058c4')) {
      api.createRecord('76c058c4', component.options)
    } else {
      api.reload('76c058c4', component.options)
    }
    module.hot.accept("./PageFooter.vue?vue&type=template&id=76c058c4&scoped=true&", function () {
      api.rerender('76c058c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/referralPage/partials/PageFooter.vue"
export default component.exports