<template>
  <div class="content-container">
    <div class="logo-container">
      <img src="/img/grupa-black-logo.svg" alt="" />
    </div>
    <div class="landing-page-bold-text">
      Accept your invitation and earn up to $1,250 when you refer others
    </div>
    <div class="landing-page-light-text">
      Grupa connects top FAANG tech experts with fast rising innovative startups
      that offer the opportunity to work on what you love and compensate in cash
      and equity.
    </div>
    <div class="cards-container">
      <div class="signup-white-card">
        <div class="card-top-content">
          <div class="card-top-text">Hire a Team</div>
          <div class="card-arrow">
            <img src="/img/onboarding/right-arrow.svg" alt="" />
          </div>
        </div>
        <div class="card-main-content">
          Get a team of top FAANG Experts to build your product.
        </div>
      </div>
      <div class="signup-white-card">
        <div class="card-top-content">
          <div class="card-top-text">SIGN UP YOUR TEAM</div>
          <div class="card-arrow">
            <img src="/img/onboarding/right-arrow.svg" alt="" />
          </div>
        </div>
        <div class="card-main-content">
          Join Grupa to work with exciting startups for cash and equity.
        </div>
      </div>
      <div class="signup-white-card card-black-bg">
        <div class="card-top-content">
          <div class="card-top-text white-text">refer & earn</div>
          <div class="card-arrow">
            <img src="/img/onboarding/right-arrow.svg" alt="" />
          </div>
        </div>
        <div class="card-main-content grey-text">
          Refer top experts & founders and earn big when they start a project.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.content-container {
  height: 830px;
  background: #1b1e22;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.logo-container {
  margin-top: 30px;
}
.landing-page-bold-text {
  font-weight: 600;
  font-size: 46px;
  line-height: 120%;
  color: #fdfdfd;
  width: 768px;
  text-align: center;
  margin-top: 127px;
}
.landing-page-light-text {
  width: 768px;
  font-weight: normal;
  font-size: 23px;
  line-height: 135%;
  text-align: center;
  color: #979da5;
  margin-top: 16px;
}
.cards-container {
  display: flex;
  margin-top: 80px;
  justify-content: space-between;
  gap: 59px;
}
.signup-white-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 5px 7px 30px rgba(21, 23, 26, 0.05);
  border-radius: 4px;
  padding: 24px;
  cursor: pointer;
}
.card-black-bg {
  background: #1b1e22;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(5px 7px 30px rgba(21, 23, 26, 0.05));
}
.card-top-content {
  display: flex;
  justify-content: space-between;
}
.card-top-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  text-transform: uppercase;
  color: #1b1e22;
}
.white-text {
  color: #ffffff;
}

.grey-text {
  color: #979da5;
}
.card-main-content {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 135%;
  color: #53585f;
  margin-top: 12px;
  width: 315px;
  text-align: left;
}
</style>
