<template>
  <div class="footer-container">
    <div class="social-media-container">
      <div class="company-location">816 37th st. Unit G Oakland CA 94608</div>
    </div>
    <div class="copy-right-container">
      <div class="company-copyright-text">
        <div class="branch-name">GRUPA</div>
        <div class="copyright-text">
          &copy; Copyright 2019 | This is a property of Platohub Inc.
        </div>
      </div>
      <div class="privacy-policy">Privacy Policy</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.footer-container {
  background: #1b1e22;
}
.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 345.75px;
}
.copy-right-container {
  border-top: 1px solid #53585f;
  padding: 48px 80px;
  display: flex;
  justify-content: space-between;
}
.company-copyright-text {
  display: flex;
  gap: 24px;
}
.branch-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #fdfdfd;
}
.copyright-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #979da5;
}
.privacy-policy {
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #979da5;
}
</style>
